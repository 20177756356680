import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';
import { useMemoStateFromFilterDeps } from '@/lib/useMemoStateFromFilterDeps';
import { Badge } from '@mantine/core';
import React from 'react';

export const EntryQuestionDebugControl = (props: { setting: DynamicEntryQuestionType }) => {
  const { setting } = props;

  const usedCompareState = useMemoStateFromFilterDeps(setting.displayFilter);

  return (
    <Badge
      onClick={() => {
        console.log({
          setting,
          usedCompareState,
        });
      }}
      size='xs'
      style={{ cursor: 'pointer' }}
      variant='gradient'>
      {setting.name}
    </Badge>
  );
};
