import React from 'react';
import { IoIosCheckmarkCircle, IoIosCloseCircle, IoIosArrowDroprightCircle, IoIosRemoveCircle } from 'react-icons/io';
import { GoArrowRight } from 'react-icons/go';

import HeadpointTextDiplay from './HeadpointTextDiplay';

export const Check = (props) => (
  <IoIosCheckmarkCircle
    {...props}
    color={'green'}
  />
);
export const NoCheck = (props) => (
  <IoIosCloseCircle
    {...props}
    color={'#e92f32'}
  />
);
export const IntermediateCheck = (props) => (
  <IoIosRemoveCircle
    {...props}
    color={'#e0dd23'}
  />
);
export const UnknownCheck = (props) => (
  <IoIosArrowDroprightCircle
    {...props}
    color={'#ffffff00'}
  />
);
export const ArrowRight = (props) => (
  <GoArrowRight
    {...props}
    color={'#535353'}
  />
);

const EnsHeadpoints = ({ headpoints, style }) => (
  <ul
    style={{
      fontWeight: 'normal',
      fontSize: '1.2rem',
      marginBlockStart: '0',
      wordBreak: 'break-all',
      listStyleType: 'none',
      paddingLeft: '0',
      ...style,
    }}>
    {headpoints.map((i) => {
      const displayText = Array.isArray(i) ? i[0] : i;
      const Icon = Array.isArray(i)
        ? i[1] === true
          ? Check
          : i[1] === false
            ? NoCheck
            : i[1] === '->'
              ? ArrowRight
              : IntermediateCheck
        : UnknownCheck;

      return (
        <li
          key={i}
          style={{
            padding: '1px 0',
            display: 'flex',
            alignItems: 'top',
            lineHeight: '1rem',
            marginBottom: '3px',
            wordBreak: 'break-word',
          }}>
          <span style={{ marginRight: '2px', display: 'inline-block' }}>
            <Icon />
          </span>
          <HeadpointTextDiplay
            text={displayText}
            markers={['ohne', 'inkl']}></HeadpointTextDiplay>
        </li>
      );
    })}
  </ul>
);

export default EnsHeadpoints;
