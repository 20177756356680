import React from 'react';
import { useDynamicFields } from '../../hooks';
import { DynamicEntryQuestionDisplay, useDisplayFilter } from '../shared/DynamicEntryQuestion';
import ZusammengassungRow from './ZusammengassungRow';
import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';
import { GetDevModeType } from '../shared/IsDevMode';
import { Group } from '@mantine/core';
import { EntryQuestionDebugControl } from '../shared/EntryQuestionDebugControl';

// eslint-disable-next-line react/require-default-props
function DynamicZusammnfassungRow({ field, bikeId = null }: { field: DynamicEntryQuestionType; bikeId?: string }) {
  const display = useDisplayFilter(field, bikeId);

  if (!display) return null;

  let valueNode: React.ReactNode = (
    <DynamicEntryQuestionDisplay
      key={1}
      setting={field}
      bikeId={bikeId}
    />
  );

  if (GetDevModeType() === 'fields')
    valueNode = (
      <Group position='apart'>
        {valueNode}
        <EntryQuestionDebugControl setting={field} />
      </Group>
    );

  return <ZusammengassungRow data={[field.displayName, valueNode]} />;
}

const DynamicZiFields = ({ position, bikeId = null }) => {
  const fields = useDynamicFields(position, (item) => item.displayPositions.includes('zusammenfassung'));

  return (
    <>
      {fields.map((field, i) => (
        <DynamicZusammnfassungRow
          key={field.name + '_' + i}
          field={field}
          bikeId={bikeId}
        />
      ))}
    </>
  );
};

export default DynamicZiFields;
