import queryString from 'query-string';
import { focusByClass } from './shared/focusByClass';
import { getOriginalWindowSearch } from './shared/QueryParams';
import { DeepPartialExcept } from './types/lib/DeepPartial';

export const focusErrorField = () => focusByClass('ensurance_error_field');

export { getUrl } from './shared/getUrl';
export { getWindowUrl } from './shared/getWindowUrl';
export { getPageMode } from './shared/getPageMode';

export const getRefererId = (
  state: DeepPartialExcept<RootState, { ensOptions: { refererOverride } }>
): string | null => {
  const parsed = queryString.parse(getOriginalWindowSearch());
  const urlRefererValues = parsed.refererId || parsed.referId;
  const urlReferer = Array.isArray(urlRefererValues)
    ? urlRefererValues.length > 0
      ? urlRefererValues[0]
      : null
    : urlRefererValues;

  if (parsed.prioritizeUrlReferer && urlReferer) {
    return urlReferer;
  } else if (window.ensRefererOverride) {
    return window.ensRefererOverride;
  } else if (state.ensOptions.refererOverride) {
    return state.ensOptions.refererOverride;
  } else if (urlReferer) {
    return urlReferer;
  } else {
    return null;
  }
};

export const getAllRefererIds = (
  state: DeepPartialExcept<RootState, { ensOptions: { refererOverride } }>
): string[] => {
  const parsed = queryString.parse(getOriginalWindowSearch());
  let urlReferer = parsed.refererId || parsed.referId;
  if (Array.isArray(urlReferer)) urlReferer = urlReferer.length === 0 ? null : urlReferer[0];

  const allOptions = [window.ensRefererOverride, state.ensOptions.refererOverride, urlReferer];
  return allOptions.filter((o) => o);
};
