import { groupBy } from '../forms/shared/lib/Array';
import { getUrl } from './shared/getUrl';
import { addCustomFilter, addUserDataPostFields } from './data/sharedPostField';
import { focusTopSelect } from './shared/focusTopSelect';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useCallback, useMemo } from 'react';
import requestOffers, { getRequestOfferAbortController } from './data/requestOffers';
import { trackByName } from './tracking';

export const ToggleCompareEnsSelect = (name) => {
  return (dispatch, getState: RootStateGetter) => {
    const currentNames = getState().ensCompare.selectedNames;

    if (currentNames.includes(name)) {
      dispatch({ type: 'SINGLE_ENS_COMPARE', name: currentNames.filter((o) => o !== name) });
      return;
    }

    const newNames = [...currentNames, name];
    const ensNamePrices = getState().ensuranceList.list.reduce((prev, next) => {
      const price = next.prices?.brutto?.find((o) => o) || Number.MAX_VALUE;
      return { ...prev, [next.name]: price };
    }, {});

    const sortedNames = newNames.sort((a, b) => {
      return (ensNamePrices[a] || 0) - (ensNamePrices[b] || 0);
    });
    dispatch({ type: 'SINGLE_ENS_COMPARE', name: sortedNames });
  };
};

export const compareDataRequested = (settings: { overrideTableId?: string } = {}) => {
  return async (dispatch, getState) => {
    const devicemode = getState().devicemode.devkey;

    const state: RootState = getState();

    const knownTables = Object.keys(state.ensCompare.savedTables).join(',');

    if (settings.overrideTableId && knownTables.includes(settings.overrideTableId)) {
      await dispatch({
        type: 'COMPARE_ENS_LOADED',
        tableId: settings.overrideTableId,
        devicemode,
      });
      return;
    }

    const request = JSON.parse(JSON.stringify(state.userData));
    const formData = new FormData();

    formData.append('knownTables', knownTables);
    addUserDataPostFields(state, request, formData);
    addCustomFilter(state, formData);

    dispatch({ type: 'COMPARE_ENS_LOADING' });

    if (settings.overrideTableId) formData.append('overrideTableId', settings.overrideTableId);
    if (settings.includeEnsuranceBaseInfo) formData.append('includeEnsuranceBaseInfo', 'true');

    const compDataNew = await fetch(getUrl(`/wp-json/ens/v1/comparetable/byRequest`), {
      method: 'post',
      body: formData,
    }).then((o) => o.json());

    const compGroups = !compDataNew.data ? null : groupBy(compDataNew.data || [], 'groupName');

    await dispatch({
      type: 'COMPARE_ENS_LOADED',
      data: compGroups,
      tableId: compDataNew.tableId,
      displayName: compDataNew?.displayName,
      groupInfos: compDataNew?.groupInfos,
      ensuranceBaseInfo: compDataNew?.ensuranceBaseInfo,
      groupPoints: !compDataNew.data ? null : getGroupPointsFromCompareData(compDataNew.data || []),
      devicemode,
    });
  };
};

const getGroupPointsFromCompareData = (compareData) => {
  return compareData.reduce((prev, next) => {
    if (next.key === 'groupPoints') {
      prev[next.groupName] = next;
    }
    return prev;
  }, {});
};

export const compareModeActivate = (abortCurrenctRequestOfferRequest = false) => {
  return async (dispatch) => {
    // Am Ende des normalen RequestOffers wird der CompareMode wieder deaktiviert. Daher muss das abgebrochen werden.
    if (abortCurrenctRequestOfferRequest) getRequestOfferAbortController()?.abort('Got a new request');

    dispatch(trackByName('nav_compare'));

    await dispatch(compareDataRequested());

    await dispatch({ type: 'COMPARE_ENS_ACTIVE_MODE' });
    dispatch(focusTopSelect(false));
  };
};

export const singleEnsCompare = (name) => {
  return async (dispatch) => {
    await dispatch({ type: 'SINGLE_ENS_COMPARE', name });
    dispatch(compareModeActivate());
  };
};

export const onResetCompareMode = (resetSelection = true, noReload = false) => {
  return async (dispatch, getState: RootStateGetter) => {
    const requestEnsurances = getState().ensuranceList.requestedEnsurances;

    if (requestEnsurances?.length > 0 && !noReload) {
      dispatch(requestOffers(true, true, false, { skipResetCompareMode: true }));
    }

    dispatch({ type: 'COMPARE_RESET_MODE', resetSelection: resetSelection });
  };
};

export const dispatchWhenCompareMode = (onCompModeAction, onNoCompModeAction) => {
  return async (dispatch, getState) => {
    const {
      ensCompare: { compareMode },
    } = getState();
    dispatch(compareMode ? onCompModeAction : onNoCompModeAction);
  };
};

const useCompareDisplayElementCount = () => {};

export const useCompareScroll = () => {
  const dispatch = useAppDispatch();
  const onNext = useCallback(() => dispatch({ type: 'COMPARE_MOVE_NEXT' }), []);
  const onPrev = useCallback(() => dispatch({ type: 'COMPARE_MOVE_PREV' }), []);

  const canMoveNext = useAppSelector((state) => state.ensCompare.canMoveNext);
  const canMovePrev = useAppSelector((state) => state.ensCompare.canMovePrev);

  const visibleNamesPosition = useAppSelector((state) => state.ensCompare.visibleNamesPosition);
  const visibleNamesCount = useAppSelector(
    (state) => state.ensCompare.visibleNamesCountOverride || state.ensCompare.visibleNamesCount
  );
  const selectedNames = useAppSelector((state) => state.ensCompare.selectedNames);

  return useMemo(
    () => ({
      onNext,
      onPrev,
      canMoveNext,
      canMovePrev,
      scrollEnable: canMoveNext || canMovePrev,
      minSelected: visibleNamesPosition + 1,
      maxSelected: visibleNamesPosition + visibleNamesCount,
      total: selectedNames.length,
    }),
    [onNext, onPrev, canMoveNext, canMovePrev, visibleNamesPosition, selectedNames]
  );
};
