import { getUrl } from '@/actions/data';
import { useAppDispatch, useAppSelector, useOnAppReady } from '@/hooks';
import { useEffect } from 'react';

import Cookies from 'js-cookie';
import { setVisitorId } from '@/reducer/visitorId';

const COOKIE_NAME = 'ensVisitorId';
const COOKIE_EXPIRE = 21;

const loadNewVisitorId = (retriesLeft = 5) => {
  return fetch(getUrl('/api/ens/v1/ensVId/retreave'))
    .then((res) => res.json())
    .catch((err) => {
      if (retriesLeft === 0) throw err;
      console.log('Retry Getting visitorId', retriesLeft);
      return new Promise((resolve) => setTimeout(resolve, 1000)).then(() => loadNewVisitorId(retriesLeft - 1));
    });
};

export const EnsVisitorId = () => {
  const isLoading = useAppSelector((state) => state.visitorId.loading);
  const isLoaded = useAppSelector((state) => state.visitorId.loaded);

  const dispatch = useAppDispatch();

  useOnAppReady(() => {
    if (isLoaded || isLoading) return;

    dispatch({ type: 'VISITOR_ID_LOADING' });

    const savedCookie = Cookies.get(COOKIE_NAME);
    if (savedCookie) {
      Cookies.set(COOKIE_NAME, savedCookie, { expires: COOKIE_EXPIRE, secure: true });
      dispatch(setVisitorId(Cookies.get(COOKIE_NAME)));
      return;
    }

    loadNewVisitorId().then((newCode) => {
      dispatch(setVisitorId(newCode));
      Cookies.set(COOKIE_NAME, newCode, { expires: COOKIE_EXPIRE, secure: true });
    });
  }, [isLoaded, isLoading]);

  return null;
};
