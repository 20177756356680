/* eslint-disable no-case-declarations */
import { getEnsFilter } from '@/actions/shared/getEnsFilter';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';
import { getIsOfferRequestProcess } from '@/processes/offerRequestProcess/IsOfferRequestProcess';
import { getAutoloadEnsurances } from '@/features/AutoloadEns';
import { GetFormularTree } from './FormularTree';

export const isSchnellrechner = (state?: RootState) => {
  return state.userData.presetName === 'schnellrechner' || state.userData.presetName === 'zundapp';
};

export const GetEvaluationContext = (state?: RootState, bikeId: string | null = null) => {
  const noStateFunc = () => {
    throw new Error('State is not present at this point');
  };

  const context = {
    prop_req: !state ? noStateFunc : (name) => state.userData[name] || 0,
    isSchnellrechner: !state ? noStateFunc : () => isSchnellrechner(state),
    getFilterkeys: () => getEnsFilter(state),
    isDevmode: () => GetIsDevMode(),
    isCompareStandaloneMode: () => (!state ? noStateFunc : getAutoloadEnsurances(state) === 'compare_standalone'),
    standaloneCompareTableId: () => (!state ? noStateFunc : state.userData.__standaloneCompareTableId || ''),
    getInstanceMode: () => state.ensfields.ens_instance_mode,
    devicemode: () => state.userData.deviceModeOverride || state.devicemode.devkey || '',
    prop_obj: (name) => (bikeId ? state.userData.bikes[bikeId][name] : '0'),
    isAngebotsprozess: () => getIsOfferRequestProcess(state),
    isGewerblicherAntragsprozess: () => {
      const usedDeviceMode = state?.userData?.deviceModeOverride || state?.devicemode?.devkey;
      return usedDeviceMode === 'gewerblich';
    },
    rechnerOptionen: () => state.options.options || [],
    prop_ens: (name) => {
      if (!state.checkout?.offer) return '';

      if (
        state.checkout.offer?.taxonomie &&
        Object.prototype.hasOwnProperty.call(state.checkout.offer.taxonomie, name)
      ) {
        return state.checkout.offer.taxonomie[name];
      }

      return (state.checkout?.offer || {})[name] || '';
    },
    voucherUsableForCashback: () => {
      return state.voucher?.voucher?.usableForCashback || false;
    },
    customProductHasAny: () => {
      return Object.values(state.customProducs.productSelection).some((v) => v.amount > 0);
    },
    customProductSelected: (productName) => {
      if (!state.customProducs.productSelection?.[productName]) return false;
      return state.customProducs.productSelection[productName]?.amount > 0;
    },
  };

  return context;
};

const operators = {
  '+': (a, b) => a + b,
  '-': (a, b) => a - b,
  '*': (a, b) => a * b,
  '/': (a, b) => a / b,
  '>': (a, b) => a > b,
  '<': (a, b) => a < b,
  '>=': (a, b) => a >= b,
  '<=': (a, b) => a <= b,
  '==': (a, b) => a == b,
  '!=': (a, b) => a != b,
  '||': (a, b) => a || b,
  '&&': (a, b) => a && b,
  or: (a, b) => a || b,
  and: (a, b) => a && b,
  not: (a) => !a,
  is_set: (a) => !!a,
  includes: (list, item: string) =>
    (Array.isArray(list) ? list : list.toString().split(','))
      .map((o: string) => o?.toLowerCase())
      .includes(item?.toLowerCase()),
  equal: (a, b) => (a == b ? 1 : 0),
  empty: (a) => {
    return !a || a == '0' || a == '';
  },
};

const resolveNode = (node, context) => {
  const allOps = { ...operators, ...context };

  switch (node.type) {
    case 'Literal':
      return node.value;
    case 'BinaryExpression':
    case 'LogicalExpression':
      const opFunc = allOps[node.operator];
      if (!opFunc) throw "Op '" + node.operator + "'not found";
      return opFunc(...[node.left, node.right].map((arg) => resolveNode(arg, context)));
    case 'CallExpression':
      const func = allOps[node.callee.name];
      if (!func) throw "Function '" + node.callee.name + "'not found";
      return func(...node.arguments.map((arg) => resolveNode(arg, context)));
    case 'ArrayExpression':
      const mas = [...node.elements].map((e) => resolveNode(e, context));
      return mas;
  }

  throw 'Unknown node ' + node.type;
};

const FormularEvaluate = (formular: string, context) => {
  const treeMain = GetFormularTree(formular);

  // @ts-ignore
  return resolveNode(treeMain.expression, context);
};

export default FormularEvaluate;
