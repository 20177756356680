import { getQueryStrings } from '../../actions/shared/QueryParams';

let isDevMode: boolean | string | null = null;

export const GetIsTestMode = () => {
  return process.env.NODE_ENV === 'test';
};

export const GetIsDevMode = () => {
  if (isDevMode === null) isDevMode = getQueryStrings().devmode;

  return isDevMode;
};

export const GetDevModeType = () => {
  const devmode = GetIsDevMode();

  switch (devmode) {
    case 'fields':
      return 'fields';
    case '1':
    case 'true':
      return 'general';
    default:
      return !!devmode;
  }
};

const IsDevMode = ({ children }) => {
  if (!GetIsDevMode()) return null;
  return children;
};

export default IsDevMode;
