import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';

import { Tooltip, Text, useMantineTheme } from '@mantine/core';
import Audit from '../../../actions/data/audit';
import { InfoIcon } from '../HookedInputs';
import { Check, NoCheck, IntermediateCheck, ArrowRight } from './EnsHeadpoints';

const RawHtml = ({ text }) => {
  const theme = useMantineTheme();

  return (
    <span
      style={{
        fontFamily: theme.fontFamily,
        fontSize: theme.fontSizes.sm,
      }}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
    />
  );
};

export const HeadpointTextDisplayWithIcon = (props: {
  text: string;
  markers?: string | string[];
  [x: string]: any;
}) => {
  const { text: _, markers, ...iconProps } = props;

  const { icon, text } = useMemo(() => {
    const iconMatch = props.text.match(/^\[(g|r|y|->)\](.*)/);

    if (iconMatch) {
      return {
        icon: iconMatch[1],
        text: iconMatch[2],
      };
    }

    return {
      icon: null,
      text: props.text,
    };
  }, [props.text]);

  const IconComponent = useMemo(() => {
    switch (icon) {
      case 'g':
        return Check;
      case 'r':
        return NoCheck;
      case 'y':
        return IntermediateCheck;
      case '->':
        return ArrowRight;
      default:
        return null;
    }
  }, [icon]);

  return (
    <Text>
      {!IconComponent ? null : (
        <>
          <IconComponent {...iconProps} />{' '}
        </>
      )}
      <HeadpointTextDiplay
        text={text}
        markers={markers}
      />
    </Text>
  );
};

const HeadpointTextDiplay = ({ text, markers }) => {
  const mappedText = useMemo(() => {
    if (!text) return null;
    if (!Array.isArray(markers)) {
      markers = [markers];
    }

    markers.forEach((m) => (text = text.replace(m, '<b>' + m + '</b>')));

    if (typeof text !== 'string' && !(text instanceof String)) {
      console.log(text);
      Audit("Try to replaceAll on '" + typeof text + "':" + JSON.stringify(text), '0', 'client:error');
    }

    text = text.replaceAll(/\[(green|red|blue|yellow)\](.*?)\[\/\1]/gm, function (...matches) {
      let color = matches[1];
      if (color === 'yellow') {
        color = '#bfad1d';
      } else if (color === 'red') {
        color = '#e92f32';
      } else if (color === 'blue') {
        color = '#273780';
      }

      return (
        "<div style='display: inline-block; border-radius: 3px; background-color: " +
        color +
        "; color: white; padding: 2px 4px; margin: -2px 0'>" +
        matches[2] +
        '</div>'
      );
    });

    // Match Tooltip regex https://regex101.com/r/q6PkTF/1
    const ttMatch = /\[tooltip\W(value=("|')(.*?)\2)\](.*?)\[\/tooltip\]/gm.exec(text);

    if (ttMatch) {
      const start = ttMatch.index;
      const end = ttMatch.index + ttMatch[0].length;

      const startText = text.substring(0, start);
      const endText = text.substring(end);

      return [
        ...(!startText
          ? []
          : [
              <RawHtml
                key='rh'
                text={text.substring(0, start)}
              />,
            ]),
        <Tooltip
          key='tooltip'
          label={ttMatch[3]}
          offset={5}>
          <div>
            <RawHtml text={ttMatch[4]} />
            &nbsp;
            <InfoIcon baseline />
          </div>
        </Tooltip>,
        !endText
          ? []
          : [
              <RawHtml
                key='rh2'
                text={text.substring(end)}
              />,
            ],
      ];
    }

    return <RawHtml text={text} />;
  }, [text]);

  return mappedText;
};

export default HeadpointTextDiplay;
