import { DynamicEntryQuestionType, EntryQuestPositionType } from '@/actions/types/DynamicEntryQuestionType';
import { useAppSelector, useDispatchCallback, useDispatchEffect, useUserData } from '@/hooks';
import { CustomProductNames, CustomProductVariants } from '@/reducer/customProducts/customProducts.types';
import { Checkbox, Group, Stack } from '@mantine/core';
import React, { useMemo } from 'react';
import { genericErrorText, useMantineLabelProps } from '../../HookedInputs';
import { setProductState } from '@/reducer/customProducts';
import InputWrapperAutowrap from '@unserkunde/enscompare-components/src/components/inputs/InputWrapperAutowrap';
import { BikeUserDataType } from '@/reducer/userData';
import { UserDataUpdateWatcher } from '../../UserDataUpdateWatcher';
import { parseCustomSettings } from '../hooks';
import requestEnsurance from '@/actions/data/requestEnsurance';
import { HeadpointTextDisplayWithIcon } from '../../headpoints/HeadpointTextDiplay';

export const CustomProductOptInQuestion = <T extends CustomProductNames>(props: {
  setting: DynamicEntryQuestionType;
  bikeId: string;
  productKey: T;
  variant: string;
  useFullHeight?: boolean;
}) => {
  const [value, onChangeFieldValue, hasError] = useUserData(props.setting.name, props.bikeId, (e) => e.target.checked);

  const labelProps = useMantineLabelProps(props.setting.displayName, props.setting.helpText, props.useFullHeight);

  const descriptionText = useMemo(() => {
    const lines = props.setting.values?.split('\n');

    if (!lines) return null;

    return (
      <Stack
        spacing={0}
        ml='xl'>
        {lines.map((line, index) => (
          <HeadpointTextDisplayWithIcon
            key={index}
            text={line}
          />
        ))}
      </Stack>
    );
  }, [props.setting?.values]);

  const { initilizeChecked } = parseCustomSettings(props.setting.options) as { initilizeChecked?: boolean };

  useDispatchEffect((dispatch) => {
    if (!initilizeChecked) return;

    dispatch((_, getState: RootStateGetter) => {
      const state = getState();
      const source = props.bikeId ? state.userData.bikes?.[props.bikeId] : state.userData;

      if (source.hasOwnProperty(props.setting.name)) return;
      onChangeFieldValue({ target: { checked: true } });
    });
  }, []);

  return (
    <InputWrapperAutowrap {...labelProps}>
      <Group
        spacing={0}
        align='center'>
        <Checkbox
          error={hasError && genericErrorText}
          checked={!!value}
          onChange={onChangeFieldValue}
        />
        {descriptionText}
      </Group>
    </InputWrapperAutowrap>
  );
};

export const CustomProductQuestionUpdateWatcher = () => {
  const customQuestions = useAppSelector((state) => state.ensfields.customquestions);
  const defaultServerProducts = useAppSelector((state) => state.customProducs.defaultServerProductSelection);

  const customProductFields: Record<EntryQuestPositionType, DynamicEntryQuestionType[]> = useMemo(
    () =>
      customQuestions
        .filter((e) => e.fieldType === 'customProductOptIn')
        .reduce(
          (acc, e) => {
            if (e.positionType === 'request') return { ...acc, request: [...acc.request, e] };
            return { ...acc, object: [...acc.object, e] };
          },
          { request: [], object: [] }
        ),
    [customQuestions]
  );

  const updateCustomProductsByFields = useDispatchCallback(
    (dispatch) => {
      dispatch((_, getState: RootStateGetter) => {
        const state = getState();

        [...customProductFields.object, ...customProductFields.request].forEach((field) => {
          const desiredAmount =
            field.positionType === 'request'
              ? state.userData[field.name]
                ? 1
                : 0
              : Object.values(state.userData.bikes).filter((bikeData: BikeUserDataType) => !!bikeData[field.name])
                  .length;

          let { productKey, variant } = parseCustomSettings(field.options) as {
            productKey: CustomProductNames;
            variant: CustomProductVariants[CustomProductNames];
          };

          if (!defaultServerProducts[productKey]) return;
          if (!variant)
            variant = defaultServerProducts[productKey].variant as CustomProductVariants[CustomProductNames];

          dispatch(setProductState(productKey, { amount: desiredAmount, variant }));
        });

        if (state.checkout.name) dispatch(requestEnsurance());
      });
    },
    [customProductFields, defaultServerProducts]
  );

  const objectFields = useMemo(() => customProductFields.object.map((e) => e.name), [customProductFields]);
  const requestFields = useMemo(() => customProductFields.request.map((e) => e.name), [customProductFields]);

  return (
    <UserDataUpdateWatcher
      objectFields={objectFields}
      requestFields={requestFields}
      onChange={updateCustomProductsByFields}
    />
  );
};
