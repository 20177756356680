import { useAppSelector } from '@/hooks';
import { getUsedFieldsFromFilter } from './FormularTree';

// Gibt den gesamten State zurück, allerdings nur, wenn sich ein Feld aus dem angegebenen Filter ändert
export const useMemoStateFromFilterDeps = (filter: string): RootState => {
  const calledFuncs = getUsedFieldsFromFilter(filter);
  const requireCustomProductWatch =
    filter.includes('customProductHasAny(') || filter.includes('customProductSelected(');

  const state = useAppSelector(
    (state) => state,
    (a: RootState, b: RootState) => {
      for (const calledFunc of calledFuncs) {
        if (calledFunc.funcName.toLocaleLowerCase() === 'prop_req') {
          for (const arg of calledFunc.args) {
            if (a.userData[arg] !== b.userData[arg]) return false;
          }
        } else if (calledFunc.funcName.toLocaleLowerCase() === 'prop_obj') {
          const objectKeys = new Set([...Object.keys(a.userData.bikes), ...Object.keys(b.userData.bikes)]);
          for (const bikeKey of objectKeys) {
            for (const arg of calledFunc.args) {
              if (a.userData.bikes?.[bikeKey]?.[arg] !== b.userData.bikes?.[bikeKey]?.[arg]) return false;
            }
          }
        } else if (calledFunc.funcName.toLocaleLowerCase() === 'devicemode') {
          if (
            (a.devicemode.devkey || a.userData.deviceModeOverride) !==
            (b.devicemode.devkey || b.userData.deviceModeOverride)
          )
            return false;
        }
      }

      if (requireCustomProductWatch) {
        if (a.customProducs.productSelection !== b.customProducs.productSelection) return false;
      }

      if (a.voucher?.voucher?.usableForCashback !== b.voucher?.voucher?.usableForCashback) return false;

      if (a.ensfields !== b.ensfields) return false;

      return true;
    }
  );

  return state;
};
