// @ts-nocheck
import React, { useEffect, useMemo } from 'react';
import { format } from 'date-fns';

import { TextInput, Checkbox as CheckboxMantine } from '@mantine/core';
import { useUserData } from '@/hooks';

export type VersicherungsbeginnMonatProps = OpenType<{ nextAvailable: 'month' | '7days' }>;

export const VersicherungsbeginnMonat = (props: {
  enableSofortschutz: boolean;
  nextAvailableMode: 'month' | '7days';
}) => {
  const { enableSofortschutz, nextAvailableMode } = props;

  const [ensuranceStartDate, setEnsuranceStartDate, hasErrorEnsuranceStartDate] =
    useUserData<string>('ensuranceStartDate');

  const [userRequestedEnsuranceStartDate, setUserRequestedEnsuranceStartDate, hasErrorUserRequestedEnsuranceStartDate] =
    useUserData<string>('userRequestedEnsuranceStartDate');

  const [sofortSchutz, setSofortSchutz, hasErrorSofortSchutz] = useUserData<boolean>('sofortschutz');

  const calculatedStartDate = useMemo(() => {
    if (!userRequestedEnsuranceStartDate) return null;
    let d = new Date(userRequestedEnsuranceStartDate);

    if (!sofortSchutz || !enableSofortschutz) {
      if (nextAvailableMode === '7days') {
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 7);

        if (d < minDate) d = minDate;
      } else {
        const currentDate = new Date();
        const minDate = new Date(
          currentDate.getFullYear() + (currentDate.getMonth() === 11 ? 1 : 0),
          (currentDate.getMonth() + 1) % 12,
          1
        );

        d = d < minDate ? minDate : d;
      }
    }
    return format(d, 'yyyy-MM-dd');
  }, [sofortSchutz, enableSofortschutz, userRequestedEnsuranceStartDate, nextAvailableMode]);

  useEffect(() => {
    if (!userRequestedEnsuranceStartDate) {
      const targetDate = new Date();
      targetDate.setDate(targetDate.getDate() + 1);
      setUserRequestedEnsuranceStartDate(format(targetDate, 'yyyy-MM-dd'), 'yyyy-MM-dd');
    }
  }, [userRequestedEnsuranceStartDate]);

  useEffect(() => {
    if (ensuranceStartDate !== calculatedStartDate) setEnsuranceStartDate(calculatedStartDate);
  }, [calculatedStartDate]);

  return (
    <>
      <TextInput
        type='date'
        label='Gewünschter Versicherungsbeginn'
        value={userRequestedEnsuranceStartDate}
        onChange={(o) => setUserRequestedEnsuranceStartDate(o.target.value)}
        error={
          hasErrorUserRequestedEnsuranceStartDate || !userRequestedEnsuranceStartDate
            ? 'Bitte wählen Sie einen gültigen Versicherungsbeginn aus'
            : false
        }
      />

      <TextInput
        type='date'
        label='Tatsächlicher Versicherungsbeginn'
        value={ensuranceStartDate}
        disabled
      />

      {enableSofortschutz && (
        <CheckboxMantine
          label='Sofortschutz'
          description='JA, ich möchte den Sofortschutz für einmalig 4,00 €. Der Schutz beginnt ab Antragsstellung. Andernfalls beginnt Ihr Schutz zum Ersten des Folgemonats.'
          checked={sofortSchutz}
          onChange={() => setSofortSchutz(!sofortSchutz)}
        />
      )}
    </>
  );
};
